import React from 'react'
import Features from '../components/Features'

const About = () => {
    return (
        <Features />
    )
}

export default About
