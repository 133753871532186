import ImageOne from '../images/EFCenter.jpeg';



export const SliderData = [
    {
        title: 'Real World Utility',
        price: 'Cards | Gear | Tickets | More...',
        path: '/home',
        label: 'Veiw Home',
        image: ImageOne,
        alt: 'House'
    },
    {
        title: 'Total Supply',
        price: '12,345 EthFans',
        path: '/home',
        label: 'Veiw Home',
        image: ImageOne,
        alt: 'House'
    },
    {
        title: 'Metaverse Access',
        price: 'Digital Assets | Events',
        path: '/home',
        label: 'Veiw Home',
        image: ImageOne,
        alt: 'House'
    },

]

